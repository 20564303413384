<template>
  <div class="h-full">
    <div class="w-full flex justify-center">
      <Title class="ml-5 my-10 font-normal text-primary inline-block">Je vous attends du lundi au samedi de 9h à 19h30</Title>
    </div>
    <div class="bg-accent pt-5">
      <Card class="flex flex-col items-center mx-5 bg-white">
        <div class="p-5 tracking-wide">
          <a href="https://goo.gl/maps/M65EMxSWeJ7njXzJ8" target="_blank" class="flex content-center font-bold text-lg md:text-2xl"
            >Au 2 Chemin de Cabrolous à Poussan <LocationMarkerIcon class="inline" size="24" />
          </a>
          <h3 class="text-center text-sm md:text-md block text-primary">à 10 minutes à pied du centre du village au pied des vignes</h3>
        </div>

        <div class="p-2 mt-3 mb-5 flex flex-col md:flex-row md:justify-around md:max-h-[600px]">
          <img src="../assets/img/map-1.gif" class="w-full md:w-[40%] my-2" />
          <img src="../assets/img/map-2.gif" class="w-full md:w-[40%] my-2" />
        </div>

        <div class="p-5 tracking-wide">
          <h3 class="text-md font-bold">Stationnement véhicule possible à l'intérieur de la propriété</h3>
        </div>

        <div class="p-2 mt-3 mb-2 flex flex-col md:flex-row md:justify-around md:max-h-[600px]">
          <img height="568" width="725" src="../assets/img/entrance-1.jpg" class="w-full md:w-[40%] my-2" />
          <img height="568" width="725" src="../assets/img/entrance-2.jpg" class="w-full md:w-[40%] my-2" />
        </div>
      </Card>
    </div>
  </div>
</template>

<script>
import { LocationMarkerIcon } from '@vue-hero-icons/outline';
import Title from '@/components/Title';
import Card from '@/components/Card';
export default {
  components: { Title, Card, LocationMarkerIcon }
};
</script>

<style></style>
