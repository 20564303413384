<template>
  <div class="p-4 my-0 border border-solid rounded-lg shadow-md">
    <slot></slot>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
